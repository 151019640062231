<template>
	<section class="section-wrapper" :class="{'first-section': value.first}">
		<base-heading :tag="value.header_tag" :heading="`${prefix}.section_title`" :subheading="`${prefix}.section_subtitle`" v-animate="`slideLeft`"/>
		<grid-template cols="2">
			<cms-multi-section class="section-elements" :value="`${prefix}.section_content`" v-animate="`opacity`"/>
			<cms-image :value="`${prefix}.image`"
				lazy
				:base="{width: isWhyUsPage ? 600 : 318, height: isWhyUsPage ? 400 : 155 }"
				:md="{width: 690, height: 200}"
				:lg="{width: 930, height: 800}"
				:xl="{width: 690, height: 460}"
                v-animate="`opacity`"
			/>
		</grid-template>
	</section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import BaseHeading from '../../components/molecules/headings/BaseHeading/BaseHeading.vue'
import GridTemplate from '../../templates/GridTemplate.vue'
export default {
	components: {
		CmsMultiSection,
		GridTemplate,
		BaseHeading
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		isWhyUsPage () {
			return this.$app.page.route.meta.alias === 'why-us'
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding: 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 8rem 0;
		::v-deep .section-elements {
			width: 570px;
		}
	}
    ::v-deep .grid-wrapper {
        @include media-breakpoint-landscape {
            grid-template-columns: 1fr;
        }
    }
	&.first-section {
		padding: 10rem 0 4rem 0;
		::v-deep .cols {
			@include media-breakpoint-up(md) {
				grid-template-columns: 1fr;
			}
			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(2, 1fr);
			}
		}
		@include media-breakpoint-up(xl) {
			padding: 16rem 0 8rem 0;
		}
	}
    ::v-deep .cms-image-wrapper {
        width: 100%;
        .base-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    ::v-deep .link {
        text-transform: lowercase !important;
        text-decoration: underline !important;
    }
}
</style>
